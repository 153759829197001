h1 {
  text-align: center;
  color: purple;
}

.table {
  display:flex;
  flex-direction: column;
  margin-right: 150px;
  margin-left: 150px;
  width: 70%;
  border: 2px solid purple;
  padding: 10px; 
  
}

.tabname {
  background-color: mediumpurple;
  margin-top: 8px;
  margin-bottom: 5px;
  
}

.section1 {
  display: flex;
  justify-content: space-between;
  background-color: lavender ;
  margin-bottom: 0px;
}

.percentage {
  display: flex;
  justify-content: end;
  background-color: lavender ;
  margin-bottom: 8px;
  margin-top: 0px;
  font-size: 0.9em;
  color: darkslategrey;
}

.flex-container1 {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.column1 {
  flex-basis: 50%;
}

.flexPeriod {
  flex-basis: 30%;
}

.column3 {
  flex-basis: 20%;
}